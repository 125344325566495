.myClass
{
color:black;
}

.card {
	/* Add shadows to create the "card" effect */
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	margin-bottom: 1rem;
	padding-top: 10px;
	background:#e9f3f6;
	width: 200px;
	height: 100px;
	cursor: pointer;
	margin: 10px;
	align-content: center;
	justify-content: center;
}

.cardHeader {
	padding-top: 1px;
	text-align: center;
	color: darkcyan;
	font-size: 9px;
	justify-content: center;
	justify-items: center;

}
.cardContent {
	padding-top: 0px;
	color: dimgrey;
	font-style: italic;
	font-size: 15px;
	text-align: center;
}